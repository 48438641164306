<template>
	<div v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
		<v-card class="my-3">
			<v-card-title>Agenda :</v-card-title>
			<v-card-text>
				<v-sheet class="pa-3" elevation="3">
					<v-sheet tile class="d-flex align-center">
						<v-btn icon class="ma-2" @click="$refs.calendar.prev()">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
                        <div class="d-flex flex-column align-center">
                            <div class="d-flex align-center">
                                <v-btn outlined class="mr-4" @click="calendarValue = new Date().toISOString()">
                                    Aujourd'hui
                                </v-btn>

                                <v-menu offset-x bottom origin="top left" transition="scale-transition" nudge-right="15" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-on="on">

                                            <v-badge bordered overlap color="notif" :content="notifiedEvents.length"  v-if="notifiedEvents.length >= 1">
                                                <v-icon>mdi-calendar-search</v-icon>
                                            </v-badge>
                                            <v-icon v-else>mdi-calendar-search</v-icon>

                                        </v-btn>
                                    </template>
                                    
                                    <v-date-picker locale="fr" v-model="calendarValue" no-title :events="colorForDatePicker"></v-date-picker>
                                </v-menu>
                            </div>
                            <v-toolbar-title v-if="$refs.calendar" class="mt-3 mb-2" v-text="new Date(calendarValue).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris', weekday: 'long', year: 'numeric', month: 'long', day: '2-digit'})"></v-toolbar-title>
                        </div>
						<v-spacer></v-spacer>
						<v-btn icon class="ma-2" @click="$refs.calendar.next()">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</v-sheet>
					<v-sheet height="65vh" min-height="500">
						<v-calendar locale="fr" ref="calendar" type="day" hide-header
                            v-model="calendarValue"
                            :events="currentEvents"
                            event-overlap-mode="column"
                            :event-overlap-threshold="30"
                            :event-color="getEventColor"
                            @mouseup:event="eventSelected"
                            @mousedown:time="startTime"
                            @mousemove:time="mouseMove"
                            @mouseup:time="endDrag"
                            @mouseleave.native="cancelDrag">
                            <template v-slot:event="{event}">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on, attrs}">
                                        <div class="px-2 d-flex flex-column flex-wrap" v-on="on" v-bind="attrs" style="width:100%;height: 100%;">
                                            <div class="mr-3" v-text="event.name + ' ' + (event.color == 'info' ? '(Privé)' : event.color == 'error' ? '(Admin)' : '(Public)')"></div>
                                            <div v-if="isTimeVisible(event)" v-text="getFormatedDate(event)"></div>
                                            <div>
                                                <a v-if="event.link.url" @click.prevent.stop="goToLink(event.link.url)" class="customWhite--text" style="text-decoration: underline">
                                                    <v-icon x-small class="mr-1" color="customWhite">mdi-open-in-new</v-icon>
                                                    <span v-text="event.link.name ? event.link.name : event.link.url"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </template>
                                    <span>
                                        <div v-text="getFormatedDate(event)"></div>
                                    </span>
                                </v-tooltip>
                            </template>
                        </v-calendar>
					</v-sheet>
				</v-sheet>

                <v-col cols="12" class="mt-5 px-3 py-0 d-flex justify-end">
                    <v-btn color="primary" fab @click="openNew">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
			</v-card-text>
		</v-card>

		<v-dialog v-model="configModalDisplayed" width="1000" persistent>
			<v-card>
				<v-card-title>
					<span class="text-h5">Configurer l'evenement</span>
				</v-card-title>
				<v-card-text v-if="configData">
					<v-container>
						<v-row>
							<v-col cols="12" class="py-0">
								<v-text-field label="Nom" v-model="configData.name" />
							</v-col>
						</v-row>
						<v-row v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role) && !configData.id">
							<v-col cols="12" class="py-0">
								<v-select v-model="visibility" :items="visibilityLevels" item-text="name" item-value="value" label="Visibilité"></v-select>
							</v-col>
						</v-row>
						<v-row class="mb-3 d-flex align-center">
							<v-col cols="1" class="d-flex justify-center">
                                <v-btn icon @click="onRedirectClick(configData.link.url)" class="mb-5">
                                    <v-icon>mdi-open-in-new</v-icon>
                                </v-btn>
							</v-col>
							<v-col cols="10" md="5" class="py-2">
								<v-text-field label="Lien (optionnel)" v-model="configData.link.url" />
							</v-col>
							<v-col cols="12" md="6" class="py-2">
								<v-text-field label="Nom du lien" v-model="configData.link.name" />
							</v-col>
						</v-row>
                        <v-row>
                            <v-col cols="12" md="6" class="py-2">
								<v-text-field type="date" label="Date de debut" v-model="configData.startDate" />
								<v-text-field type="time" label="Heure de debut" v-model="configData.startTime" />
                            </v-col>
                            <v-col cols="12" md="6" class="py-2">
								<v-text-field type="date" label="Date de fin" v-model="configData.endDate" />
								<v-text-field type="time" label="Heure de fin" v-model="configData.endTime" />
                            </v-col>
                        </v-row>
						<v-row class="mb-3 d-flex align-center">
                            <v-col cols="12" class="d-flex align-center">
                                <v-btn icon @click="addMetadata" class="mr-2">
                                    <v-icon color="primary">mdi-plus</v-icon>
                                </v-btn>
                                <div>Metadata :</div>
                            </v-col>
                            <v-col cols="12" v-for="(data, i) in configData.metadata" :key="i" class="py-0">
                                <v-row class="d-flex align-center my-2">
                                    <v-col cols="12" md="4" class="py-0">
                                        <v-text-field :label="'Clé n°' + (i+1)" v-model="data.key" />
                                    </v-col>
                                    <v-col cols="11" md="7" class="py-0">
                                        <v-text-field :label="'Valeur n°' + (i+1)" v-model="data.content" />
                                    </v-col>
                                    <v-col cols="1" class="pt-0">
                                        <v-btn icon @click="configData.metadata.splice(i,1);$forceUpdate()" class="my-2">
                                            <v-icon color="error">mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
						</v-row>
                        <v-row class="d-flex align-center justify-center mt-5">
                            <v-btn class="mx-3" color="secondary" @click="close">Annuler</v-btn>
                            <v-btn class="mx-3" color="primary" @click="save">Valider</v-btn>
                            <v-btn class="mx-3" color="error" @click="deleteEvent" v-if="configData.id">Supprimer</v-btn>
                        </v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import logger from "@/assets/functions/logger"
	import notifier from "@/assets/functions/notifier"
    
    import themeConfig from "@themeConfig"
    const colorsLight = themeConfig.themes.light
    const colorsDark = themeConfig.themes.dark

	import Swal from 'sweetalert2/dist/sweetalert2.js'
    
    import Event from "@/classes/Event"

	export default {
		name: "Agenda",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
		data() {
			return {
				calendarValue: new Date().toISOString(),
                visibilityLevels:[
                    {
                        name: "Tous",
                        value: "all"
                    },
                    {
                        name: "Admin",
                        value: "admin"
                    },
                    {
                        name: "Moi",
                        value: "me"
                    },
                ],
                visibility: "me",
                globalEvents: [],
                adminEvents: [],
                personalEvents: [],
				selectedEvent: null,
				newEvent: {
					name: "",
					start: null,
					end: null,
                    link: {
                        name: null,
                        url: null
                    },
                    metadata: [],
					timed: true,
					color: "light",
					setup: false,
				},
				configModalDisplayed: false,
                configData: null,
                unsub: [],

                notifiedEvents: []
			}
		},
        async created() {
            this.unsub.push(
                Event.listenByRoomAndByType(this.profile.currentRoom, "global", (events) => {
                    events.forEach(event => {
                        event.timed = true
                        event.color = "primary"
                    })
                    this.globalEvents = events
                }),
            )

            this.unsub.push(
                Event.listenByRoomByTypeAndByUser(this.profile.currentRoom, "personal", this.profile.id, (events) => {
                    events.forEach(event => {
                        event.timed = true
                        event.color = "info"
                    })
                    this.personalEvents = events
                }),
            )

            if(new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(this.profile.role)){
                this.unsub.push(
                    Event.listenByRoomAndByType(this.profile.currentRoom, "admin", (events) => {
                        events.forEach(event => {
                            event.timed = true
                            event.color = "error"
                        })
                        this.adminEvents = events
                    }),
                )
            }
            
        },
        mounted () {
            this.$refs.calendar.scrollToTime((new Date().getHours()-2)+':'+new Date().getMinutes())
            this.checkNotifAgenda()
        },
		computed: {
            events(){
				if (new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(this.profile.role)) {
					return this.globalEvents.concat(this.personalEvents).concat(this.adminEvents)
				} else {
					return this.globalEvents.concat(this.personalEvents)
				}
            },
			currentEvents() {
				if (this.newEvent.start && this.newEvent.end) {
					return this.events.concat(this.newEvent)
				} else {
					return this.events
				}
			}
		},
        watch : {
            calendarValue: {
                handler(newVal, oldVal){
                    if (this.notifications && this.notifications.agenda) {
                        let dateBegining = new Date(newVal).getTime()
                        let dateEnding = dateBegining + 24*60*60*1000
                        let filteredEvent = this.events.filter(event => event.start > dateBegining && event.start < dateEnding)
                        
                        let idList = []
                        this.notifications.agenda.map(notif => idList.push(notif.content))
                        let filteredNotif = filteredEvent.filter(event => idList.includes(event.id))

                        for (let event of filteredNotif) {
                            this.deleteNotif(event.id)
                        }
                    }
                }
            },
            notifications() {
                this.checkNotifAgenda()
            }
        },
		methods: {
            checkNotifAgenda(){
                let notifs = []
                if (this.notifications && this.notifications.agenda) {
                    for (let notif of this.notifications.agenda) {
                        if (notif.content === null) notif.delete()
                        notifs.push(notif.content)
                    }
                }
                this.globalEvents.forEach(event => {
                    if (notifs.includes(event.id)) {
                        notifs.splice(notifs.indexOf(event.id), 1, new Date(event.start).toISOString().slice(0, 10))
                    }
                })
                this.notifiedEvents = notifs
            },
            colorForDatePicker(date){
                let dateBegining = new Date(date).getTime()
                let dateEnding = dateBegining + 24*60*60*1000

                let filteredEvent = this.events.filter(event => event.start > dateBegining && event.start < dateEnding)

                let tmp_color = []
                let currentTheme = this.profile.theme == 'dark' ? colorsDark : colorsLight
                let hasFound = {
                    admin: false,
                    personal: false,
                    global: false,
                }
                let idList = []

                let filteredNotif = []
                if (this.notifications && this.notifications.agenda) {
                    this.notifications.agenda.map(notif => idList.push(notif.content))
                    filteredNotif = filteredEvent.filter(event => idList.includes(event.id))
                }

                if (filteredNotif.length > 0) tmp_color = currentTheme.notif
                else for (let event of filteredEvent) {
                    if (!hasFound.admin && event.type === 'admin') {
                        tmp_color.push(currentTheme.error)
                        hasFound.admin = true
                    }
                    else if (!hasFound.personal && event.type === 'personal') {
                        tmp_color.push(currentTheme.info)
                        hasFound.personal = true
                    }
                    else if (!hasFound.global && event.type === 'global') {
                        tmp_color.push(currentTheme.primary)
                        hasFound.global = true
                    }
                }
                return (tmp_color)
            },
            deleteNotif(eventId){
                if (this.notifications && this.notifications.agenda) {
                    for (let notif of this.notifications.agenda) {
                        if (eventId === notif.content) {
                            notif.delete()
                            return
                        }
                    }
                }
            },
            goToLink(url){
                this.configModalDisplayed = false
                window.open(url)
            },
            isTimeVisible(event){
                let nbLine = event.link ? 75 : 50
                return (new Date(event.end).getTime() - new Date(event.start).getTime() >= nbLine * 60 * 1000)
            },
            addMetadata(){
                if(!this.configData.metadata){
                    this.configData.metadata=[]
                }
                this.configData.metadata.push({
                    key: '',
                    content: ''
                })
                this.$forceUpdate()
            },
            getFormatedDate(event){
                let startDate = new Date(event.start)
                let endDate = new Date(event.end)
                let stringDate = String(startDate.getHours()).padStart(2,'0') + ':' + String(startDate.getMinutes()).padStart(2,'0') + ' - ' + String(endDate.getHours()).padStart(2,'0') + ':' + String(endDate.getMinutes()).padStart(2,'0')

                return stringDate
            },
            openNew(){
                this.configData = {}
                this.configData.id = null
                this.configData.name = null
                this.configData.startDate = null
                this.configData.startTime = null
                this.configData.endDate = null
                this.configData.endTime = null
                this.configData.link = {}
                this.configData.link.name = null
                this.configData.link.url = null
                this.configData.metadata = []
                this.configModalDisplayed = true
            },
            close() {
                this.configModalDisplayed = false
                this.configData = null
            },
            async save(){
                if(this.configData.id){
                    this.configData.event.name = this.configData.name
                    this.configData.event.start = new Date(this.configData.startDate + " " + this.configData.startTime).getTime(),
                    this.configData.event.end = new Date(this.configData.endDate + " " + this.configData.endTime).getTime(),
                    this.configData.event.link = this.configData.link
                    this.configData.event.metadata = this.configData.metadata
                    logger.log(this.profile.id, "UPDATE EVENT", this.profile.email + " a modifié un event " + this.configData.event.type + " (" + this.configData.event.name + ")")
                    this.configData.event.save()
                }else{
                    switch(this.visibility){
                        case "all":
                            let globalEvent = new Event(
                                null,
                                this.profile.currentRoom,
                                "global",
                                this.configData.name,
                                new Date(this.configData.startDate + " " + this.configData.startTime).getTime(),
                                new Date(this.configData.endDate + " " + this.configData.endTime).getTime(),
                                null,
                                this.configData.link,
                                this.configData.metadata
                            )
                            globalEvent.save().then(() => {
                                notifier.send(this.profile.currentRoom,"agenda",new Date().getTime(),globalEvent.id)
                            })
                            break;
                        case "admin":
                            let adminEvent = new Event(
                                null,
                                this.profile.currentRoom,
                                "admin",
                                this.configData.name,
                                new Date(this.configData.startDate + " " + this.configData.startTime).getTime(),
                                new Date(this.configData.endDate + " " + this.configData.endTime).getTime(),
                                null,
                                this.configData.link,
                                this.configData.metadata
                            )
                            adminEvent.save()
                            break;
                        case "me":
                            let personalEvent = new Event(
                                null,
                                this.profile.currentRoom,
                                "personal",
                                this.configData.name,
                                new Date(this.configData.startDate + " " + this.configData.startTime).getTime(),
                                new Date(this.configData.endDate + " " + this.configData.endTime).getTime(),
                                this.profile.id,
                                this.configData.link,
                                this.configData.metadata
                            )
                            personalEvent.save()
                            break;
                    }
                    logger.log(this.profile.id, "ADD EVENT", this.profile.email + " a ajouté un evenement")
                }   

                this.configModalDisplayed = false
                this.configData = null
            },
            deleteEvent(){
                Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if(result.isConfirmed){
                        logger.log(this.profile.id, "DELETE EVENT", this.profile.email + " a supprimé un event " + this.configData.event.type + " (" + this.configData.event.name + ")")
                        this.configData.event.delete()
                        this.configModalDisplayed = false
                        this.configData = null
					}
				})
            },
			getEventColor(event) {
				return event.color
			},
            onRedirectClick(link){
                window.open(link, '_blank')
            },
			resetNewEvent() {
				this.newEvent = {
					name: "",
					start: null,
					end: null,
                    link: {
                        name: null,
                        url: null
                    },
                    metadata: [],
					timed: true,
					color: "light",
					setup: false,
				}
			},
			eventSelected(e) {
                if(new Array('Animator', 'Admin', 'SuperAdmin').includes(this.profile.role) || e.event.color == "info"){
                    this.selectedEvent = e.event
                    this.configData = {}
                    this.configData.event = e.event
                    this.configData.id = e.event.id
                    this.configData.name = e.event.name
                    this.configData.startDate = new Date(e.event.start).toISOString().slice(0,10)
                    this.configData.startTime = new Date(e.event.start).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
                    this.configData.endDate = new Date(e.event.end).toISOString().slice(0,10)
                    this.configData.endTime = new Date(e.event.end).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
                    this.configData.color = e.event.color
                    this.configData.link = e.event.link
                    this.configData.metadata = e.event.metadata
                    this.configModalDisplayed = true
                }
			},
			startTime(e) {
				this.resetNewEvent()
				if (!this.selectedEvent) {
					this.newEvent.start = new Date(e.date + " " + e.time)
					this.newEvent.setup = true
				}
			},
			mouseMove(e) {
				if (this.newEvent.start && this.newEvent.setup) {
					this.newEvent.end = new Date(e.date + " " + e.time)
				}
			},
			endDrag(e) {
				if (this.selectedEvent) {
					this.selectedEvent = null
				} else {
					this.newEvent.setup = false
					if (this.newEvent.start && this.newEvent.end) {
						this.configData = {}
                        this.configData.id = null
                        this.configData.name = this.newEvent.name
                        this.configData.startDate = this.newEvent.start.toISOString().slice(0,10)
                        this.configData.startTime = this.newEvent.start.toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
                        this.configData.endDate = this.newEvent.end.toISOString().slice(0,10)
                        this.configData.endTime = this.newEvent.end.toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
                        this.configData.link = this.newEvent.link
                        this.configData.metadata = this.newEvent.metadata
                        this.configModalDisplayed = true
						this.resetNewEvent()
					} else {
						this.resetNewEvent()
					}
				}
			},
			cancelDrag(e) {
				this.resetNewEvent()
			},
		},
        destroyed() {
            this.unsub.forEach(unsub => unsub())
        },
	}
</script>
